<template>
  <div class="container py-5">
    <p class="fw-bold">2024 AMWC</p>
    <p>時間：2024/03/27-29</p>
    <p>地點：Grimaldi Forum, Monaco</p>
    <p>展位：D14</p>

    <p>
     Join Sylton at the AMWC in Monaco! Stop by our booth, number D14, from March 27th to 29th.
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_37.jpg"
    />


  </div>
</template>
<script>
export default {};
</script>
